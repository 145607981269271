import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRouterPath } from '../../helpers/path';

export const LinkComponent = React.forwardRef(({ children, href, to, ...rest }, ref) => {
    const { i18n } = useTranslation();

    if (href) {
        return <a href={href} target="_blank" rel="noreferrer" {...rest}>{children}</a>;
    }

    let cleanTo = to;

    if (to && to.indexOf('route:') === 0) {
        cleanTo = getRouterPath(i18n, to.substring(6), i18n.language, true);
    }

    return <Link ref={ref} to={cleanTo} {...rest}>{children}</Link>;
});

export default LinkComponent;
