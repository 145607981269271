import { Facebook, Instagram } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { withTranslation, Trans } from 'react-i18next'
import TikTokIcon from "../components/general/TikTokIcon";
import { getRootScopeTitle } from '../helpers/scope'
import useRootScope from '../hoc/hooks/useRootScope'
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties'
import { path, routes } from '../routes'
import Content from './../components/content/Content'
import SectionTitle from './../components/general/section-title'
import { SchemaBreadcrumb } from '../helpers/schema'

const ContactPage = ({ t, i18n }) => {
	const rootScope = useRootScope();
	const properties = useRootScopeProperties();
	const meta = properties.meta || {};
	const config = properties.config || {};

	const email = meta.email || 'info@athenastudies.nl';
	const emailComplaints = meta.emailComplaints || 'klachten@athenastudies.nl';
	const emailVacancy = meta.emailVacancy || 'vacature@athenastudies.nl';

	const addressName = meta.addressName || 'AthenaStudies';
	const addressLine1 = meta.addressLine1 || 'Herengracht 450';
	const addressLine2 = meta.addressLine2 || '1017CA, Amsterdam, the Netherlands';
	const addressCommerceNumber = meta.addressCommerceNumber || '62750151';

	return (
		<Content padding>
			<Content.Header>
				<h1>{t('contact.title_contact')}</h1>
			</Content.Header>
			<Helmet>
				<title>{t('contact.title_contact')}</title>
				<meta property="og:title" content={`${getRootScopeTitle(rootScope)} - ${t('contact.title_contact')}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: t('contact.title_contact'), path: path(i18n, routes.CONTACT)}
					])}
				</script>
			</Helmet>
			<SectionTitle>{t('contact.contact_info')}</SectionTitle>
			<Grid container columnSpacing={2}>
				<Grid item xs={12} md={4}>
					<strong>{t('contact.address_info')}</strong>
					<p>
						{addressName}<br/>
						{addressLine1}<br/>
						{addressLine2}<br/>
						KVK {addressCommerceNumber}
					</p>
					<p>
						<Link to={path(i18n, routes.STATIC.TERMS)}>{t('contact.terms')}</Link>
					</p>
					<br/>
				</Grid>
				<Grid item xs={12} md={4}>
					<strong>{t('contact.info')}</strong>
					<p>
						<Trans
							i18nKey="contact.info_text"
							values={{ email }}
						>
							Voor alle algemene vragen of bij onduidelijkheden, mail naar <a href={`mailto:${email}`}>{email}</a>. Vergeet niet te vermelden:
						</Trans>
					</p>
					<ul className="purple-bullets">
						<li>{t('contact.info_question1')}</li>
						<li>{t('contact.info_question2')}</li>
						{!config.hideContactLastInfoQuestion && (
							<li>{t('contact.info_question3')}</li>
						)}
					</ul>
					<strong>{t('contact.social_media')}</strong>
					{Boolean(meta.socialFacebook) && (
						<Typography paragraph sx={{ mb: 1 }}>
							<a href={`https://www.facebook.com/${meta.socialFacebook}/`} target="_blank">
								<Facebook
									fontSize="small"
									sx={{ color: '#3b5998', transform: 'translateY(4px)', mr: 1 }}
								/>
								<span>@{meta.socialFacebook}</span>
							</a>
						</Typography>
					)}
					{Boolean(meta.socialInstagram) && (
						<Typography paragraph sx={{ mb: 1 }}>
							<a href={`https://www.instagram.com/${ meta.socialInstagram}/`} target="_blank">
								<Instagram
									fontSize="small"
									sx={{ color: 'common.black', transform: 'translateY(4px)', mr: 1 }}
								/>
								<span>@{meta.socialFacebook}</span>
							</a>
						</Typography>
					)}
					{Boolean(meta.socialTiktok) && (
						<Typography paragraph sx={{ mb: 1 }}>
							<a href={`https://www.tiktok.com/${meta.socialTiktok}`} target="_blank">
								<TikTokIcon
									fontSize="small"
									sx={{ color: 'common.black', transform: 'translateY(4px)', mr: 1 }}
								/>
								<span>{meta.socialTiktok}</span>
							</a>
						</Typography>
					)}
					<br/>
				</Grid>
				<Grid item xs={12} md={4}>
					{Boolean(config.showVacancy) &&
						<div>
							<strong>{t('contact.vacancies.title')}</strong>
							<p>
								<Trans
									i18nKey="contact.vacancies.text"
									values={{ email: emailVacancy }}
								>
									Heb je interesse om bij ons te komen werken? Bekijk de <a href="https://www.werkenbijathena.nl/" target="_blank" rel="noreferrer">vacatures</a> of neem contact op via: <a href={`mailto:${emailVacancy}`}>{emailVacancy}</a>.
								</Trans>
							</p>
							<br/>
						</div>
					}
					<strong>{t('contact.complaints')}</strong>
					<p>{t('contact.complaints_text')} <a href={`mailto:${emailComplaints}`}>{emailComplaints}</a>.</p>
				</Grid>
			</Grid>
		</Content>
	);
}

export default withTranslation()(ContactPage)
