import { Box, Divider, MenuItem, Select, Alert, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { validProperties } from '../actions/properties';
import { fetchSubSubScopes } from '../actions/scopes';
import Content from '../components/content/Content';
import CourseBundle from '../components/course/CourseBundle';
import CourseBundleSelectDialog from '../components/course/CourseBundleSelectDialog';
import CourseGroupCard from '../components/course/CourseGroupCard';
import Loader from '../components/loader';
import ScopeDescription from '../components/scope/ScopeDescription';
import { SchemaBreadcrumb } from '../helpers/schema';
import { getRootScopeTitle, scopeAbbrOrName, scopeHideAbbr, scopeUrlSegment } from '../helpers/scope';
import { useFetchData } from '../hoc/hooks/useFetchData';
import { useLoadingDispatch } from '../hoc/hooks/useLoadingDispatch'
import { path, routes } from '../routes';
import { scopeAndParentsSelector, scopeByIdAndSubSubScopesSelector } from '../selectors/scope';

const PREFIX = 'ScopeCoursePage';

const classes = {
    topBar: `${PREFIX}-topBar`,
    topBarRight: `${PREFIX}-topBarRight`,
    select: `${PREFIX}-select`,
    cards: `${PREFIX}-cards`
};

const StyledDiv = styled('div')(({ theme }) => ({
    [`& .${classes.topBar}`]: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(2),
	},

    [`& .${classes.topBarRight}`]: {
		display: 'none', // TODO: Decide sorting options
		alignItems: 'center',
	},

    [`& .${classes.select}`]: {
		display: 'inline-block',
		marginLeft: theme.spacing(2),
		minWidth: '12rem',
		'& .MuiSelect-root': {
			padding: theme.spacing(1),
		},
	},

    [`& .${classes.cards}`]: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
		gridGap: theme.spacing(2),
	}
}));

export const scopeCoursePageLoader = (store, params) => {
	return store.dispatch(fetchSubSubScopes(params.courseId));
}

const ScopeCoursePage = () => {
	const params = useParams();
	const [dispatch, loading, error] = useLoadingDispatch();
	const { t, i18n } = useTranslation();

	const course = useSelector((state) => scopeByIdAndSubSubScopesSelector(state, {
		id: params.courseId,
	}));
	const { company, institute, faculty, study } = useSelector((state) => scopeAndParentsSelector(state, { id: course?.parent }));
	const partner = useSelector(state => state.init.partner);

	const [activeBundle, setActiveBundle] = useState(null);
	const { data: scopeProperties } = useFetchData('ScopeProperties');

	const courseProperties = useMemo(() => (
		Array.isArray(scopeProperties) && course ? validProperties(course, scopeProperties, 'hiddenOnCoursePage') : []
	), [scopeProperties, course]);

	const handleSelectBundle = useCallback((bundle) => {
		setActiveBundle(bundle);
	}, [setActiveBundle]);

	const handleCloseBundle = useCallback(() => {
		setActiveBundle(null);
	}, [setActiveBundle]);

	const handleChangeSort = () => {
		console.log('handle sort') // TODO: sort
	}

	const handleShowDialog = (group) => {
		setSelectedGroup(group);
	}

	useEffect(() => {
		if (!course || !study) {
			dispatch(fetchSubSubScopes(params.courseId));
		}
	}, [course]);

	// TODO: handle better, especially because courses can be closed
	//  have a button to navigate to the study abbreviation, even if the study could not be loaded yet
	if((error && error.errorCode === 404) || (course === undefined && !loading)) return (
		<Content><Alert severity="info" icon={false}>{t('course.course_not_found')}</Alert></Content>
	);

	if (!course || !study || loading) {
		return <Content><Loader /></Content>
	}

	const description = t('course.seo_description', {
		study: study?.name,
		course: course.name
	});

	return (
		<Content>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} alignItems="center">
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={path(i18n, routes.STUDY, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study))} />
					</Grid>
					<Grid item xs={12} md={4} lg={6}>
						<h1>{course.name}</h1>
						<h2>{study.name}{!scopeHideAbbr(study) && ` (${study.abbreviation})`}</h2>
					</Grid>
					<Grid item xs={!!partner ? 12 : 0} md={4} lg={3} sx={{ textAlign: { md: 'right' } }}>
						<Content.Partner partner={study.partner} partnerGlobal={!!partner} />
					</Grid>
				</Grid>
			</Content.Header>
			<Helmet>
				<title>{`${scopeAbbrOrName(institute)} ${study.name}`}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={`${getRootScopeTitle(company)} - ${t('courses.og_course_title', {
					name: `${scopeAbbrOrName(institute)} ${course.name}`
				})}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: 'Cursussen', path: path(i18n, routes.COURSES)},
						{name: scopeAbbrOrName(institute), path: path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))},
						{name: study.name, path: path(i18n, routes.STUDY, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study))},
						{name: 'Cursus', path: path(i18n, routes.COURSE, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study), course.id)},
					])}
				</script>
			</Helmet>
			<StyledDiv>
				{activeBundle && (
					<CourseBundleSelectDialog study={study} bundle={activeBundle} onClose={handleCloseBundle} />
				)}
				<ScopeDescription
					scopeDescription={course?.description}
					scopeProperties={courseProperties}
					scope={course}
				/>
				<CourseBundle
					courseId={course.id}
					onSelectBundle={handleSelectBundle}
					mode="course"
				/>
				<div className={classes.topBar}>
					<div><strong>{t('course.no_of_groups', { number: course?.subScopes.length || 0 })}</strong></div>
					<div className={classes.topBarRight}>
						<span>{t('course.sortBy.label')}</span>
						<Select
							value="date"
							disabled
							onChange={handleChangeSort}
							variant="outlined"
							className={classes.select}
						>
							<MenuItem value="date">{t('course.sortBy.date')}</MenuItem>
						</Select>
					</div>
				</div>
				<div className={classes.cards}>
					{course?.subScopes?.map(group => (
						<CourseGroupCard
							group={group}
							course={course}
							onShowDialog={handleShowDialog}
							key={group.id}
						/>
					))}
				</div>
				{course?.subScopes.length === 0 && (
					<Alert severity="info" icon={false}>{t('course.no_groups')}</Alert>
				)}
			</StyledDiv>
			<Box>
				<Divider sx={{ my: 2 }} />
				<em className="text-muted">
					{t('course.seo_description_prefix', { institute: scopeAbbrOrName(institute) })} {description}
				</em>
			</Box>
		</Content>
	)
}

export default ScopeCoursePage;

