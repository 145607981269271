import { styled, Tabs, Tab, Alert } from '@mui/material';
import { camelize } from 'humps';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import Content from '../../components/content/Content';
import Markdown from '../../components/markdown/Markdown';
import { getRootScopeTitle } from '../../helpers/scope';
import useRootScope from '../../hoc/hooks/useRootScope';
import NotFoundPage from '../general/NotFoundPage';
import staticPages from './staticPages';
import { getLangAndPath } from '../../helpers/path';

// Handle the following metadata pattern:
// ==meta:KEY:OPTIONAL_FALLBACK==
function handleDynamicMeta(content, meta = {}) {
    return content.replace(/==meta:(.*?)==/g, (match, clean) => {
        const [key, fallback] = clean.split(':');
        return meta[key] || fallback || clean;
    });
}

// Customized function of what is in locale.js to account for a different fallbackLng structure
function getCustomLocaleMessage(message, i18n) {
    if (!message) return '';
    if (typeof message === 'string') return message;
    return message[i18n.language] || message[i18n.fallbackLng] || Object.values(message)[0] || '';
}

function getImportedContentForLocale(content, locale, i18n) {
    if (!content) return '';
    if (typeof content === 'function') return content;
    return content[locale] || content[i18n.fallbackLng] || Object.values(content)[0] || (() => '');
}

const StyledStaticPageContent = styled('div')({
    minHeight: '400px',
    paddingBottom: '24px',

    '& img': {
        maxWidth: '100%',
    },

    '& > ul.nav': {
        fontSize: '16px',
    },
});

export async function staticContentLoader(routerPage, request, i18n, rootScopeProperties) {
    const { staticPageMapping } = rootScopeProperties.config || {};
    const { pathname } = new URL(request.url);
    const { lang } = getLangAndPath(i18n, pathname);

    const fixedKey = camelize(routerPage.key);
    let page = routerPage;
    if (staticPageMapping?.[fixedKey]) {
        const pages = staticPages(i18n, lang);
        page = pages.find((currPage) => currPage.key === staticPageMapping[fixedKey]) || routerPage;
    }

    const data = await getImportedContentForLocale(page.content, lang, i18n)();
    let dataLocale = i18n.fallbackLng;
    if (['string', 'function'].includes(typeof page.title)) {
        dataLocale = page.defaultLocale;
    } else if (typeof page.title === 'object' && page.title[lang]) {
        dataLocale = lang;
    }
    return {
        page,
        dataLocale,
        dataContent: handleDynamicMeta(data?.default || ''),
    };
}

function StaticPage() {
    const { i18n, t } = useTranslation();
    const rootScope = useRootScope();
    const location = useLocation();
    const { page, dataContent, dataLocale } = useLoaderData();

    const pages = useMemo(() => {
        const { lang } = getLangAndPath(i18n, location.pathname);
        return staticPages(i18n, lang);
    }, [i18n, location.pathname]);

    // Create a custom page default
    const pageI18n = useMemo(() => ({
        ...i18n,
        ...page ? {
            fallbackLng: page.defaultLocale || i18n.options.fallbackLng[0],
        } : {},
    }), [i18n, i18n.language, page]);

    if (!page) return (
        <NotFoundPage />
    );

    // TODO: if page is a number or string, it is a server page, try to load it
    if (typeof page === 'string' || typeof page === 'number') return (
        <NotFoundPage />
    );

    const ParentComponent = page.parentPage || 'div';
    const title = getCustomLocaleMessage(page.title, pageI18n);
    const titleMeta = page.titleMeta ? getCustomLocaleMessage(page.titleMeta, pageI18n) : title;

    return (
        <ParentComponent>
            <Content padding>
                <Helmet>
                    <title>{titleMeta}</title>
                    <meta property="og:title" content={`${getRootScopeTitle(rootScope)} - ${titleMeta}`} />
                    {page.description && (
                        <meta name="description" content={getCustomLocaleMessage(page.description, pageI18n)} />
                    )}
                </Helmet>
                <Content.Header>
                    <h1>{title}</h1>
                </Content.Header>

                <StyledStaticPageContent>
                    {page.linkList && (
                        <Tabs value={location.pathname || page.key}>
                            {page.linkList
                                .map((key) => pages.find(currPage => currPage.key === key))
                                .filter(Boolean)
                                .map(subPage => (
                                    <Tab
                                        component={Link}
                                        to={subPage.path || `/p/${subPage.key}`}
                                        value={subPage.path || subPage.key}
                                        label={getCustomLocaleMessage(subPage.title, pageI18n)}
                                        key={subPage.key}
                                    />
                                ))}
                        </Tabs>
                    )}
                    {dataContent && (
                        <>
                            {dataLocale !== i18n.language && (
                                <Alert severity="warning">{t('staticPage.incorrectLanguage')}</Alert>
                            )}
                            <article lang={dataLocale}>
                                <Markdown>{dataContent}</Markdown>
                            </article>
                        </>
                    )}
                </StyledStaticPageContent>
            </Content>
        </ParentComponent>
    );
}

export default StaticPage;
