import PropTypes from 'prop-types';
import React from 'react';
import { Title } from '@athenagroup/components';

function HomePageWidgetTitle({ title, description, textAlignMobile, textAlign, moreWidth }) {
    return (
        <Title title={title} description={description} descriptionAlign={textAlign} descriptionAlignMobile={textAlignMobile} moreWidth={moreWidth} />
    );
}

HomePageWidgetTitle.disableTitle = true;

HomePageWidgetTitle.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    textAlign: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
    textAlignMobile: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
    moreWidth: PropTypes.bool,
};

export default HomePageWidgetTitle;
