import { Box, Grid } from '@mui/material';
import React, { useMemo, createElement } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReactMarkdown, { uriTransformer } from 'react-markdown'
import { visit } from 'unist-util-visit'
import remarkDirective from 'remark-directive'
import { Link } from 'react-router-dom'
import { getPagePath, getRouterPath } from '../../helpers/path'
import staticPages from '../../pages/static/staticPages'
import MarkdownButton from './MarkdownButton'
import MarkdownCard from './MarkdownCard';
import MarkdownIframe from './MarkdownIframe'
import MarkdownListTable from './MarkdownListTable'
import MarkdownQuote from './MarkdownQuote'

export const getTransformedUri = (to, i18n) => {
	const pages = useMemo(() => staticPages(i18n), [i18n]);
	if (to.indexOf('route:') === 0) {
		return getRouterPath(i18n, to.substr(6), i18n.language, true);
	}
	if (to.indexOf('key:') === 0) {
		return getPagePath(pages, to.substr(4));
	}
	return to;
}

const customUriTransformer = (uri) => {
	// Internal key and route linking, will be picked up by custom renderer
	if(uri.indexOf('key:') === 0 || uri.indexOf('route:') === 0){
		return uri;
	}

	return uriTransformer(uri);
};

function customPlugin() {
	return (tree) => {
		visit(tree,
			["textDirective", "leafDirective", "containerDirective"],
			(node) => {
				node.data = {
					hName: node.name,
					hProperties: node.attributes,
					...node.data
				};
			}
		);
	};
}

const customComponents = {
	quote: ({node, children, ...props}) => (
		<MarkdownQuote {...props}>
			{children}
		</MarkdownQuote>
	),
	iframe: ({node, children, ...props}) => (
		<MarkdownIframe src={children[0]} {...props} />
	),
	listTable: ({node, children, ...props}) => (
		<MarkdownListTable items={children[0]} {...props} />
	),
	button: ({node, children, ...props}) => (
		<MarkdownButton to={children[0]} {...props} />
	),
	listIcon: ({children, ...props}) => (
		<div className={`listIcon-${children[0]} ${props.color ? `listIconColor-${props.color}` : ''}`} />
	),
    card: ({ node, children, ...props }) => (
        <MarkdownCard items={children[0]} {...props} />
    ),
    cardWrapper: ({ children }) => (
        <Grid container spacing={2} sx={{ my: 2 }}>
            {React.Children.map(children, (child, i) => (
                <Grid key={i} item md={6} xs={12}>
                    {child}
                </Grid>
            ))}
        </Grid>
    ),
    spacer: ({ spacing }) => (
        <Box my={parseInt(spacing, 10) || 2} />
    ),
    anchor: ({ children }) => (
        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
        children[0] ? <a id={children[0]} /> : null
    ),
};

const Markdown = ({ children }) => {
	const { i18n } = useTranslation();
	const pages = useMemo(() => staticPages(i18n), [i18n]);

	const components = useMemo(() => ({
		a: ({ node, ...props }) => {
			if(props.href.indexOf('key:') === 0) return (
				<Link to={getPagePath(pages, props.href.substr(4))}>{props.children}</Link>
			);

			if(props.href.indexOf('route:') === 0) return (
				<Link to={getRouterPath(i18n, props.href.substr(6), i18n.language, true)}>{props.children}</Link>
			);

			return createElement('a', props);
		},
		...customComponents,
	}), [pages, i18n]);

	const plugins = useMemo(() => [
		remarkDirective,
		customPlugin,
	], []);

    return (
		<ReactMarkdown
			className="markdown markdown-new"
			components={components}
			transformLinkUri={customUriTransformer}
			children={children}
			remarkPlugins={plugins}
		/>
    );
};

Markdown.propTypes = {
	children: PropTypes.node,
};

export default Markdown
