import { Box, Divider, Alert, Grid } from "@mui/material";
import { intersperse } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchScopeByAbbrPath } from '../actions/scopes';
import Content from '../components/content/Content';
import Courses from '../components/course/Courses'
import Loader from '../components/loader';
import ScopeDescription from '../components/scope/ScopeDescription';
import { SchemaBreadcrumb } from '../helpers/schema';
import { getRootScopeTitle, scopeAbbrOrName, scopeHideAbbr, scopeUrlSegment } from '../helpers/scope';
import { useLoadingDispatch } from '../hoc/hooks/useLoadingDispatch'
import useRootScopeProperties from '../hoc/hooks/useRootScopeProperties'
import { useSetLastPath } from '../hoc/hooks/useSetLastPath'
import { path, routes } from '../routes';
import { abbrPathSelector, scopeAndParentsSelector, scopeByReverseAbbrPathSelector } from '../selectors/scope';
import { toggleStudyLocalePrompt } from '../reducers/i18n';

export const scopeStudyPageLoader = (store, params) => {
	const state = store.getState();
    const study = scopeByReverseAbbrPathSelector(state, { params });

    // Do not fetch if data is already there (same behavior as the component below)
    if (study && study.subScopes) return null;

	const abbrPath = abbrPathSelector(state, { params });
	return store.dispatch(fetchScopeByAbbrPath(abbrPath, 2));
}

const ScopeStudyPage = () => {
	const params = useParams();
	const [loadingDispatch, loading, error] = useLoadingDispatch();
	const dispatch = useDispatch();

	const rootScopeProperties = useRootScopeProperties();
	const study = useSelector((state) => scopeByReverseAbbrPathSelector(state, { params }));
	const { company, institute, faculty } = useSelector((state) => scopeAndParentsSelector(state, { id: study?.parent }));
	const abbrPath = useSelector((state) => abbrPathSelector(state, { params }));
	const partner = useSelector(state => state.init.partner);

	const { t, i18n } = useTranslation();

	useEffect(() => {
		if(!study || !study.subScopes){
			loadingDispatch(fetchScopeByAbbrPath(abbrPath, 2));
		}else{
			// TODO: do setLastPath action (with id list)
		}
	}, [study, abbrPath]);

    useEffect(() => {
        if (study?.locale && study.locale.substring(0, 2) !== i18n.language) {
            dispatch(toggleStudyLocalePrompt(true));
        }
    }, [])

	useSetLastPath(study);

	const coursePathCreator = useCallback((courseId) => (
		path(i18n, routes.COURSE, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study), courseId)
	), [institute, study]);

	if((error && error.errorCode === 400) || (study === undefined && !loading)) return (
		<Content><Alert severity="info" icon={false}>{t('courses.study_not_found')}</Alert></Content>
	);

	if(!study || loading) return (
		<Content><Loader/></Content>
	);

	const description = t('courses.seo_description', {
		study: study.name
	});

	return (
		<Content>
			<Content.Header>
				<Grid container rowSpacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }} alignItems="center">
					<Grid item xs={12} md={4} lg={3} sx={{ textAlign: { md: 'left' } }}>
						<Content.Back to={rootScopeProperties.config.showFacultyScope ? (
							path(i18n, routes.FACULTY, scopeUrlSegment(institute), scopeUrlSegment(faculty))
						) : (
							path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))
						)} />
					</Grid>
					<Grid item xs={12} md={4} lg={6}>
						<h1>{study.name}{!scopeHideAbbr(study) && ` (${study.abbreviation})`}</h1>
						<h2>{institute.name}{rootScopeProperties.config.showFacultyScope ? ` - ${faculty.name}` : ''}</h2>
					</Grid>
					<Grid item xs={!!partner ? 12 : 0} md={4} lg={3} sx={{ textAlign: { md: 'right' } }}>
						<Content.Partner partner={study.partner} partnerGlobal={!!partner} />
					</Grid>
				</Grid>
			</Content.Header>
			<Helmet>
				<title>{`${scopeAbbrOrName(institute)} ${study.name}`}</title>
				<meta name="description" content={description} />
				<meta property="og:title" content={`${getRootScopeTitle(company)} - ${t('courses.og_title', {
					name: `${scopeAbbrOrName(institute)} ${study.name}`
				})}`} />
				<script type="application/ld+json">
					{SchemaBreadcrumb([
						{name: 'Cursussen', path: path(i18n, routes.COURSES)},
						{name: scopeAbbrOrName(institute), path: path(i18n, routes.INSTITUTE, scopeUrlSegment(institute))},
						{name: study.name, path: path(i18n, routes.STUDY, scopeUrlSegment(institute), scopeUrlSegment(faculty), scopeUrlSegment(study))},
					])}
				</script>
			</Helmet>
			<ScopeDescription scopeDescription={study.properties?.pageDescription} />
			<Courses study={study} coursePathCreator={coursePathCreator} />
			<Box>
				<Divider sx={{ my: 2 }} />
				<em className="text-muted">
					{t('courses.seo_description_prefix', {
						institute: scopeAbbrOrName(institute),
					})} {description}
				</em>
				<ul className="keyword-list">
					{intersperse(' ', (study.properties && study.properties.keywords || []).map(k => (
						<li key={k}>{k}</li>
					)))}
				</ul>
			</Box>
		</Content>
	)
}

export default ScopeStudyPage;
