import { Popover, popoverClasses, styled, useMediaQuery } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Header as BaseHeader, headerDesktopClasses, headerMobileClasses } from '@athenagroup/components';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getCustomLocaleMessage } from '../../helpers/locale';
import { getRouterPath } from '../../helpers/path';
import useRootScopeProperties from '../../hoc/hooks/useRootScopeProperties';
import { rootScopeSelector } from '../../selectors/scope';
import { path, routes } from '../../routes';
import HeaderStudyLocaleDialog from './HeaderStudyLocaleDialog';
import desktopLogo from './wreath.png';
import mobileLogo from './logo.png';
import mobileLogoSchool from './logo-school.png';
import mobileLogoGmat from './logo-gmat.png';
import { LinkComponent } from '../general/LinkComponent';
import { getRootScopeTitle } from '../../helpers/scope';
import UserProfileElement from './UserProfileElement';
import HeaderLocaleSave from './HeaderLocaleSave';
import { hideLocaleSaveDialog } from '../../reducers/i18n';

const mobileLogos = {
    default: mobileLogo,
    ms: mobileLogoSchool,
    gmat: mobileLogoGmat,
};

const getMobileLogo = (properties) => mobileLogos[properties.config.customLogo] || mobileLogos.default;

export const createCustomMenu = (menuConfig, i18n) => menuConfig && menuConfig.length > 0 && menuConfig.map(item => {
    let { to } = item;

    // Internal routing through routeDefinitions.js
    if (to && to.indexOf('route:') === 0) {
        to = getRouterPath(i18n, to.substr(6), i18n.language, true);
    }

    return {
        name: getCustomLocaleMessage(item.title, i18n),
        linkAttributes: {
            to,
            href: item.href,
        },
    };
});

const StyledHeader = styled('div')(() => ({
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 100,
}));

const StyledPopover = styled(Popover)(() => ({
    [`& .${popoverClasses.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        borderRadius: '4px',
        overflow: 'hidden',
        zIndex: 10000,
    },
}));

const locales = {
    nl: {
        name: 'Nederlands',
        tag: 'nl',
    },
    en: {
        name: 'English',
        tag: 'en',
    },
    de: {
        name: 'Deutsch',
        tag: 'de',
    },
};

const selectInitState = (state) => state.init;

const selectI18nState = (state) => state.init.i18n;

function Header() {
    const rootScope = useSelector(rootScopeSelector);
    const { showStudyLocalePrompt, showLocaleSaveDialog } = useSelector(selectI18nState);
    const { i18n, t } = useTranslation();
    const { search, pathname } = useLocation();
    const params = useParams();
    const isMobileOrTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const { config: { hideHeaderRightMenu }, customLogoUrl } = useRootScopeProperties();

    const ref = useRef();

    const languageMenu = useMemo(() => Object.values(locales).map((locale) => ({
        locale,
        LinkComponent: Link,
        linkAttributes: { to: getRouterPath(i18n, pathname, locale.tag, false, params), search },
        /* eslint-disable-next-line -- i18n.language is necessary to correctly update the dropdown after a language switch,
        otherwise the language we came from will not be clickable */
    })), [i18n, i18n.language, params, pathname, search]);

    const links = useMemo(() => createCustomMenu(rootScope.properties.menuHeader, i18n) || [
        { name: t('header.menu.home'), linkAttributes: { to: path(i18n, routes.HOME) } },
        { name: t('header.menu.courses'), linkAttributes: { to: path(i18n, routes.COURSES) } },
        { name: t('header.menu.faq'), linkAttributes: { to: path(i18n, routes.FAQ) } },
        { name: t('header.menu.contact'), linkAttributes: { to: path(i18n, routes.CONTACT) } },
    ], [i18n, rootScope.properties.menuHeader, t]);

    const dispatch = useDispatch();
    const { me } = useSelector(selectInitState);

    const handleLocaleSaveClose = () => {
        dispatch(hideLocaleSaveDialog());
    };

    return (
        <>
            <StyledHeader ref={ref}>
                <BaseHeader
                    siteName={getRootScopeTitle(rootScope)}
                    menu={links}
                    currentLocale={locales[i18n.language] || locales.nl}
                    translationItems={languageMenu}
                    LinkComponentHome={LinkComponent}
                    desktopLogoUrl={customLogoUrl || desktopLogo}
                    mobileLogoUrl={customLogoUrl || getMobileLogo(rootScope.properties)}
                    homeLinkAttributes={{ to: path(i18n, routes.HOME) }}
                    userProfileElement={hideHeaderRightMenu ? undefined : <UserProfileElement />}
                    DefaultLinkComponent={LinkComponent}
                    sx={{
                        [`& .${headerDesktopClasses.root} .${headerDesktopClasses.nav} a`]: {
                            color: { md: 'common.white', sx: 'common.white' },
                        },
                        [`& .${headerDesktopClasses.root} .${headerDesktopClasses.rightMenu} > button`]: {
                            color: { md: 'common.white', sx: 'common.white' },
                        },
                        [`& .${headerDesktopClasses.root} .${headerDesktopClasses.rightMenu} > button svg`]: {
                            color: { md: 'common.white', sx: 'common.white' },
                        },
                        [`& .${headerDesktopClasses.root} .${headerDesktopClasses.logo}`]: {
                            textDecoration: 'none',
                            '&:after': {
                                backgroundColor: 'common.white',
                            },
                        },
                        [`& .${headerMobileClasses.root} .${headerMobileClasses.navRight} a`]: {
                            color: 'common.white',
                        },
                        [`& .${headerMobileClasses.root} button`]: {
                            color: 'common.white',
                        },
                        '& button, & a': {
                            color: 'common.white',
                        },
                    }}
                />
                { (showLocaleSaveDialog && !!me) && (
                    <StyledPopover
                        open={true}
                        anchorOrigin={isMobileOrTablet ? {
                            vertical: 'bottom',
                            horizontal: 'center',
                        } : {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={isMobileOrTablet ? { vertical: 'top', horizontal: 'center' } : undefined}
                        anchorEl={ref.current?.querySelector?.('#header-locale-button') || ref.current?.querySelector?.('.HeaderMobile-navCenter')}
                        onClose={handleLocaleSaveClose}
                    >
                        <HeaderLocaleSave />
                    </StyledPopover>
                ) }
            </StyledHeader>
            {showStudyLocalePrompt && (
                <HeaderStudyLocaleDialog />
            )}
        </>
    );
}

export default Header;
