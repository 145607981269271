import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ClassicBanners } from '@athenagroup/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import staticPages from '../../../pages/static/staticPages';
import { getPagePath, getRouterPath } from '../../../helpers/path';

function HomePageWidgetBanners({ items, animation }) {
    const { i18n } = useTranslation();
    const getPath = useCallback((item) => {
        const pages = staticPages(i18n);
        if (item.to.indexOf('key:') === 0) {
            return getPagePath(pages, item.to.substring(4));
        }

        if (item.to.indexOf('route:') === 0) {
            return getRouterPath(i18n, item.to.substring(6), i18n.language, true);
        }

        return null;
    }, [i18n]);

    return (
        <ClassicBanners
            items={items.map(item => ({ ...item,
                LinkComponent: item.href ? 'a' : Link,
                linkProps: item.href ? { href: item.href } : { to: item.to && getPath(item) } }))}
            animation={animation}
        />
    );
}

function customPropType(props, propName, componentName) {
    if (!props.href && !props.to) {
        return new Error(`One of props 'href' or 'to' was not specified in '${componentName}'.`);
    }
    return true;
}

HomePageWidgetBanners.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: customPropType,
        to: customPropType,
        iconSrc: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    animation: PropTypes.string,
};

export default HomePageWidgetBanners;
